.home-page{
    @media screen and (min-width: 540px) and (min-height: 600px) {
        min-height: 720px;
    }
    padding-top: 4vw;
    height: auto;
    position: relative;
    text-align: center;
    min-height: 100%;
    .content-page{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        flex: 1;
        padding: 15px 0;
    }
    .block-citeo{
        padding-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .line-pict{
            width: 100%;
            height: 100%;
            height: 2px;
        }
        .logo-citeo{
            max-width: 24vw;
            max-height: 56px;
            margin: 0 40px;
        }
    }
    .logo-home{
        max-width: 50vw;
        max-height: 187px;
        margin: 0 auto;
        // margin-top: 50px;
        @media screen and (min-width: 540px) {
            max-width: 270px;
        }
    }
    .text-home{
        font-family: $font;
        font-weight: 500;
        text-align: center;
        font-size: 5.3vw;
        line-height: 7vw;
        max-width: 75vw;
        margin: 20px auto;
        // margin-top: 30px;
        @media screen and (min-width: 540px) {
            font-size: 26px;
            line-height: 37px;
            max-width: 61%;
        }
    }
    .btn-home{
        width: 60vw;
        height: 16vw;
        // margin: 40px auto 0px auto;
        box-shadow: 0px 8px 25px -16px $black;
        a{
            font-size: 5.5vw;
            line-height: 16vw;
        }
        @media screen and (min-width: 540px) {
            width: 260px;
            height: 65px;
            a{
                font-size: 24px;
                line-height: 65px;
            }
        }
    }
    .block-footer{
        width: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
        overflow: hidden;
        //margin-top: -26vw;
        @media screen and (min-width: 540px) and (min-height: 600px) {
            margin-top: 0;
        }
        .footer-block{
            width: 100%;
            background-color: $white;
            .block-citeo{
                padding-top: 0;
            }
        }
    }
    .chrono-home{
        max-width: 65%;
        margin: 0 auto;
        position: relative;
        //bottom: -30vw;
        margin-bottom: 10px;
        z-index: -1;
        @media screen and (min-width: 540px) {
            max-width: 340px;
            max-height: 232px;
            //bottom: -160px;
        }
    }
}
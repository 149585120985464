.logo-bg-color {
    padding: 5px;
    border-radius: 15px;
    display: inline-block;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 1);
    width: 140px;
    height: 30px;
    margin-top: 14px;
    padding: 5px 5px 10px 5px;
    
    .logo {
        max-width: 100%;
        max-height: 100px;
    }
}
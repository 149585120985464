.modal-confirm{
    position: fixed;
    background-color: $blacktransp;
    width: 100%;
    height: 100vh;
    top: 0;
    justify-content: center;
    align-items: center;
    z-index: 99999999;
    display: none;
    &.show{
        display: flex;
    }
    .content-modal{
        width: 80%;
        padding: 14vw 5vw 4vw 5vw;
        background-color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @media screen and (min-width: 540px){
            max-width: 540px;
            padding: 50px 5px 5px 5px;
        }
    }
    .title{
       font-size: 5.7vw;
       margin-bottom: 10vw;
       text-align: center;
       max-width: 90%;
       line-height: 7.1vw;
       @media screen and (min-width: 540px){
            margin-bottom: 30px;
            font-size: 28px;
        }
    }
    .btn{
        width: 40vw;
        height: 12vw;
        margin-bottom: 6vw;
        span{
            font-size: 4.8vw;
            line-height: 12vw;
            width: 100%;
            height: 100%;
            display: block;
        }
        @media screen and (min-width: 540px){
            width: 160px;
            height: 60px;
            margin-bottom: 20px;
            span{
                font-size: 18px;
                line-height: 60px;
            }
        }
    }
    &.modal-ios{
        .content-modal{
            position: relative;
            top: -8%;
        }
    }
}
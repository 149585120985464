html{
    width: 100%;
    height: 100%;
    max-width: 540px;
    margin: auto;
}

body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family:  $font;
    color: $black;
}

div,
button,
span,
a,
body,
input,
label{
    -webkit-tap-highlight-color:transparent;
}

div,
span,
a,
label{
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.page{
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    // min-height: 640px;
    position: relative;
}

.bottom-page{
    position: absolute;
    bottom: 0;
    width: 100%;
}
.content-page{
    text-align: center;
    width: 100%;
}

a{
    text-decoration: none;
    color: $black;
}

#root{
    height: 100%;
}

.line-pict{
    background-image: url("../assets/img/line.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.errorMessage {
    border: 2px solid $red;
    padding: 10px;
    text-align: center;
    font-size: 1.3em;
    border-radius: 20px;
}
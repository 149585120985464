.error-page{
    height: auto;
    position: relative;
    text-align: center;
    min-height: 100%;
    @media screen and (min-height : 855px){
        min-height: 100vh;
    }
    @media screen and (min-width: 540px) and (min-height: 600px) {
        min-height: 100vh;
    }
    .content-page{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        // padding: 15px 0;
    }
    .title-page{
        font-size: 8vw;
        text-align: center;
        margin-top: 10px;
        @media screen and (min-width: 540px){
            font-size: 60px;
        }
    }
    .text-page{
        font-size: 5vw;
        line-height: 7vw;
        text-align: center;
        max-width: 90%;
        margin: 10px auto;
        @media screen and (min-width: 540px){
            font-size: 21px;
            line-height: 31px;
            max-width: 64%
        }
    }

    .bottom-page{
        position: relative;
    }
}
label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    span {
        display: inline-block;
        position: relative;
        background-color: transparent;
        width: 8vw;
        height: 4.8vw;
        transform-origin: center;
        border: 1px solid $black;
        border-radius: 50%;
        vertical-align: -6px;
        margin-right: 10px;
        transition: background-color 150ms 200ms, transform 350ms cubic-bezier(.78,-1.22,.17,1.89);
        outline: none;
      &:before {
        content: "";
        width: 0px;
        height: 0px;
        border-radius: 50%; // so that the tick has nice rounded look
        background: #fff;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: auto;
        transform: translateY(-50%) rotate(45deg);
        transition: width 50ms ease 50ms;
        opacity: 0;
        display: none;
      }

      &:after {
        content: "";
        width: 0;
        height: 0px;
        border-radius: 50%; // so that the tick has nice rounded look
        background: #fff;
        position: absolute;
        transform: translateY(-50%) rotate(305deg);
        top: 50%;
        left: 0;
        right: 0;
        margin: auto;
        transition: width 50ms ease;
      }
    }
  }

  input[type="checkbox"] {
      display: none; // hide the system checkbox

    // Let's add some effects after the checkbox is checked

    &:checked {
      + label {
        span {
          background-color: $yellow;
          transform: scale(1.25); // enlarge the box
          border: 1px solid $yellow;
          &:after {
            width: 60%;
            height: 60%;
            background: $white;
            transition: width 150ms ease 100ms; // enlarge the tick
          }

          &:before {
            width: 65%;
            height: 65%;
            background: $white;
            transition: width 150ms ease 100ms; // enlarge the tick
            opacity: 0;
            visibility: hidden;
          }
        }
      }
    }
  }
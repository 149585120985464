.waste-page{
    @media screen and (min-height: 855px){
        min-height: 920px;
    }
    height: auto;
    min-height: 100%;
    .block-product{
        position: relative;
        .content-product{
            .product-picture{
                max-width: 25%;
                margin: auto;
            }
            .name-product{
                margin: 0px auto 15px auto;
                font-size: 4.5vw;
                &::first-letter{
                    text-transform: uppercase;
                }
                @media screen and (min-width: 540px) {
                    font-size: 18px;
                }
            }
        }
    }
    .content-waste-page{
        text-align: center;
    }
    .waste-sorting-block{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 10px;
        .waste-block{
            margin: 0 5% 8%;
            min-width: 34%;
            max-width:  34%;
        }
        .waste{
            width: 18vw;
            height: 18vw;
            position: relative;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 0px 15px -11px #000000 inset;
            border-radius: 15px;
            .waste-picture{
                width: 65%;
            }
            @media screen and (min-width: 540px){
                width: 110px;
                height: 110px;
                .waste-picture{
                    width: 65%;
                }
            }
        }
        .name-waste{
            text-align: center;
            margin-top: 10px;
            font-size: 4vw;
            @media screen and (min-width: 540px){
                font-size: 18px;
            }
            span{
                display: block;
            }
        }
    }
    .bottom-page{
        position: relative;
    }
}